import types from '../mutation-types';
import { FEATURE_FLAGS } from '../../featureFlags';
import KanbanAPI from '../../api/kanban';

const state = {
  isEnabled: false,
  items: [],
  loading: false,
  error: null,
  config: {
    title: 'Gerenciador de Pedidos',
    defaultView: 'kanban',
    webhookUrl: '',
    webhookSecret: '',
    autoAssignment: false,
    notificationsEnabled: false,
    supportEmail: '',
    supportPhone: '',
    supportChatHours: '',
    supportChatEnabled: false
  }
};

const getters = {
  isKanbanEnabled: (_state) => _state.isEnabled,
  getKanbanItems: (_state) => _state.items,
  isLoading: (_state) => _state.loading,
  getError: (_state) => _state.error,
  getKanbanConfig: state => state.config,
  getKanbanTitle: state => state.config?.title || 'Gerenciador de Pedidos',
  getSupportEmail: state => state.config?.supportEmail || '',
  getSupportPhone: state => state.config?.supportPhone || '',
  getSupportChatHours: state => state.config?.supportChatHours || '',
  getSupportChatEnabled: state => state.config?.supportChatEnabled || false
};

const actions = {
  setKanbanEnabled({ commit }, isEnabled) {
    commit(types.SET_KANBAN_ENABLED, isEnabled);
  },

  async fetchKanbanItems({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await KanbanAPI.getItems();
      commit('SET_KANBAN_ITEMS', response.data);
      return response.data;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createKanbanItem({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await KanbanAPI.createItem(payload);
      commit('ADD_KANBAN_ITEM', response.data);
      return response.data;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateItemStructure({ commit }, { itemId, structure }) {
    commit('SET_LOADING', true);
    try {
      const response = await KanbanAPI.updateItemStructure(itemId, { item: { item_structure: structure } });
      commit('UPDATE_ITEM_STRUCTURE', { itemId, structure: response.data.item_structure });
      return response.data;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateItemDetails({ commit }, { itemId, details }) {
    commit('SET_LOADING', true);
    try {
      const response = await KanbanAPI.updateItemDetails(itemId, { item: { item_details: details } });
      commit('UPDATE_ITEM_DETAILS', { itemId, details: response.data.item_details });
      return response.data;
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async fetchKanbanConfig({ commit }) {
    try {
      const response = await KanbanAPI.fetchConfig();
      
      const config = {
        title: response.data.KANBAN_TITLE || 'Gerenciador de Pedidos',
        defaultView: response.data.KANBAN_DEFAULT_VIEW || 'kanban',
        webhookUrl: response.data.KANBAN_WEBHOOK_URL || '',
        webhookSecret: response.data.KANBAN_WEBHOOK_SECRET || '',
        autoAssignment: response.data.KANBAN_AUTO_ASSIGNMENT === 'true',
        notificationsEnabled: response.data.KANBAN_NOTIFICATION_ENABLED === 'true',
        supportEmail: response.data.KANBAN_SUPPORT_EMAIL || '',
        supportPhone: response.data.KANBAN_SUPPORT_PHONE || '',
        supportChatHours: response.data.KANBAN_SUPPORT_CHAT_HOURS || '',
        supportChatEnabled: response.data.KANBAN_SUPPORT_CHAT_ENABLED === 'true'
      };
      
      commit('SET_KANBAN_CONFIG', config);
      return config;
    } catch (error) {
      console.error('Erro ao carregar configurações do Kanban:', error);
      const defaultConfig = {
        title: 'Gerenciador de Pedidos',
        defaultView: 'kanban',
        webhookUrl: '',
        webhookSecret: '',
        autoAssignment: false,
        notificationsEnabled: false,
        supportEmail: '',
        supportPhone: '',
        supportChatHours: '',
        supportChatEnabled: false
      };
      commit('SET_KANBAN_CONFIG', defaultConfig);
      return defaultConfig;
    }
  }
};

const mutations = {
  [types.SET_KANBAN_ENABLED](_state, isEnabled) {
    _state.isEnabled = isEnabled;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  SET_KANBAN_ITEMS(state, items) {
    state.items = items;
  },
  ADD_KANBAN_ITEM(state, item) {
    state.items.push(item);
  },
  UPDATE_ITEM_STRUCTURE(state, { itemId, structure }) {
    const item = state.items.find(i => i.id === itemId);
    if (item) {
      item.item_structure = structure;
    }
  },
  UPDATE_ITEM_DETAILS(state, { itemId, details }) {
    const item = state.items.find(i => i.id === itemId);
    if (item) {
      item.item_details = details;
    }
  },
  SET_KANBAN_CONFIG(state, config) {
    state.config = config;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
